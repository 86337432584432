/**
 * @Author: William Alexander Livesley
 * @Date:   2018-11-09
 * @Filename: cognito-client.ts
 * @Copyright: Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import { UpdateTwitterUserInput } from "@radivision/graphql/lib/ts/graphql/mutations/update-twitter-user-input";
import { TwitterUser } from "@radivision/graphql/lib/ts/graphql/twitter-user";
import { UpdateLinkedInUserInput } from "@radivision/graphql/lib/ts/graphql/mutations/update-linked-in-user-input";
import { LinkedInUser } from "@radivision/graphql/lib/ts/graphql/linked-in-user";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { sign as AWS4_SIGN } from "aws4";
import { CognitoIdentityPoolTokens } from "../component-configuration/cognito-identity-pool-tokens";
import { LocalStorageKeys } from "../utilities/local-storage-keys";
import { storageFactory } from "../utilities/local-storage-factory";
import {
  GetUserAttributeVerificationCodeRequest,
  VerifyUserAttributeRequest,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
const localStore = storageFactory(() => localStorage);

/**
 * Initializes the AWS config with the region information for the Cognito user pool.
 */

/**
 * A class containing utility functions to interact with AWS Cognito.
 */
export class CognitoClient {
  private static AWS;

  /**
   * Returns a promise to replace an AWS Cognito user's password using the current password and a valid new password.
   *
   * @param {string} currentPassword The current password of AWS Cognito user.
   *
   * @param {string} newPassword The new password for AWS Cognito user account.
   *
   * @returns {Promise<CognitoIdentityServiceProvider.ChangePasswordResponse>} A promise to change password of an AWS Cognito User account
   */
  public static changeUserPassword(currentPassword: string, newPassword: string): Promise<any> {
    let cognitoUser: AmazonCognitoIdentity.CognitoUser;
    const data: AmazonCognitoIdentity.ICognitoUserPoolData = {
      ClientId: process.env.AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    };
    let promise: Promise<any>;
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(data);

    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null || cognitoUser === undefined) {
      promise = Promise.reject(new Error("[pvJ3wU0bJEqnba62GKiy4Q] There is no current user"));
    } else {
      promise = new Promise(
        (resolve: (results: AmazonCognitoIdentity.CognitoUserSession) => void, reject: (e: any) => void): void => {
          cognitoUser.getSession((err: any = null, session: AmazonCognitoIdentity.CognitoUserSession = null): void => {
            if (err === null) {
              localStore.setItem(
                LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
                JSON.stringify({
                  [process.env.AWS_COGNITO_LOGIN_KEY]: session.getIdToken().getJwtToken(),
                }),
              );
              resolve(session);
            } else {
              reject(err);
            }
          });
        },
      ).then(
        (): Promise<any> => {
          return new Promise((resolve: (results: any) => void, reject: (e: any) => void): void => {
            cognitoUser.changePassword(currentPassword, newPassword, (err: Error = null, result: any) => {
              if (err === null) {
                resolve(result);
              } else {
                reject(err);
              }
            });
          });
        },
      );
    }
    return promise;
  }

  /**
   * Returns a promise to allow a user to use a confirmation code to reset a forgotten password.
   *
   * @param {string} email The email address of the account whose password is to be reset.
   *
   * @param {string} confirmationCode The confirmation code to reset a forgotten password.
   *
   * @param {string} password The new password.
   *
   * @returns {Promise<any>} A promise to confirm that the user has reset the
   * forgotten password.
   */
  public static confirmForgotPassword(email: string, confirmationCode: string, password: string): Promise<any> {
    return import("./aws-sdk").then((AWS) => {
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const provider = new AWS.AWS_CLIENTS.CognitoIdentityServiceProvider();
        const params: any = {
          ClientId: process.env.AWS_COGNITO_CLIENT_ID,
          ConfirmationCode: confirmationCode,
          Password: password,
          Username: email?.toLowerCase(),
        };

        provider.confirmForgotPassword(
          params,
          (
            err: {
              code: string;
              message: string;
            } = null,
            result: any,
          ): void => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      });
    });
  }

  /**
   * Function used to indicate if current acquired Cognito credentials expired or need to be refreshed
   * @returns {boolean} return boolean indicating if current cognito credentials needs to refresh or not
   * @memberof CognitoClient
   */
  public static checkTokenExpiration() {
    // assuming AWS.config.credentials is already feeded with available credentials.

    // return await import("./aws-sdk").then((module) => {
    //   const AWS = module.AWS_CLIENTS.AWS;

    // });

    return (CognitoClient.AWS.config.credentials as any)?.needsRefresh();
  }
  /**
   * Returns a promise to create new Cognito user account using an email address as a key.
   *
   * @param {string} email The Email address of the user.
   *
   * @param {string} password The password of the user.
   *
   * @return {Promise<AmazonCognitoIdentity.ISignUpResult>} The promise returned when the user is registered with Cognito. The account
   * still requires confirmation.
   */
  public static createAccountUsingEmailAndPassword(
    email: string,
    password: string,
    name: string = null,
  ): Promise<AmazonCognitoIdentity.ISignUpResult> {
    return new Promise(
      (resolve: (user: AmazonCognitoIdentity.ISignUpResult) => void, reject: (e: any) => void): void => {
        const ATT_LIST: any[] = [];
        const cognitoUserPool: AmazonCognitoIdentity.CognitoUserPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
          ClientId: process.env.AWS_COGNITO_CLIENT_ID,
        });
        const USER_ID: string = email.toLowerCase();

        if (name !== null && name !== undefined) {
          ATT_LIST.push(
            new AmazonCognitoIdentity.CognitoUserAttribute({
              Name: "name",
              Value: name,
            }),
          );
        }

        ATT_LIST.push(
          new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: "email",
            Value: USER_ID,
          }),
        );

        cognitoUserPool.signUp(
          USER_ID,
          password,
          ATT_LIST,
          null,
          (err: Error = null, result: AmazonCognitoIdentity.ISignUpResult = null) => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      },
    );
  }

  /**
   * Returns a promise to send a message to a given email address containing a confirmation code to be used to reset the
   * password of the account with the given email address as username.
   *
   * @param {string} email The email for which to reset the password.
   *
   * @returns {Promise<any>} The promise to send the message containing the
   * confirmation code to the given email address.
   */
  public static forgotAccountPassword(email: string): Promise<any> {
    return import("./aws-sdk").then((module) => {
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const PARAMS: any = {
          ClientId: process.env.AWS_COGNITO_CLIENT_ID,
          Username: email.toLowerCase(),
        };
        const PROVIDER = new module.AWS_CLIENTS.CognitoIdentityServiceProvider();

        PROVIDER.forgotPassword(
          PARAMS,
          (
            err: {
              code: string;
              message: string;
            } = null,
            result: any,
          ): void => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      });
    });
  }

  /**
   * Returns a promise containing the current Cognito identity credentials.
   *
   * @returns {Promise<CognitoIdentity.Credentials>} The current identity credentials.
   */
  public static getCognitoIdentityCredentials(): Promise<any> {
    let promise: Promise<any> = import("./aws-sdk");

    promise = promise.then((module) => {
      // Checks if the AWS configuration has been populated
      let internalPromise: Promise<any> = Promise.resolve();

      if (CognitoClient.AWS === undefined || CognitoClient.AWS === null) {
        // The AWS configuration has not been populated
        CognitoClient.AWS = module.AWS_CLIENTS.AWS;
        CognitoClient.AWS.config.region = process.env.AWS_REGION;
        CognitoClient.AWS.config.maxRetries = 3;
        internalPromise = internalPromise.then(() => {
          return CognitoClient.updateCognitoIdentityCredentials();
        });
      }

      internalPromise = internalPromise.then(() => {
        return CognitoClient.checkTokenExpiration()
          ? CognitoClient.refreshCognitoIdentityCredentials()
          : Promise.resolve();
      });

      internalPromise = internalPromise.then(
        (): Promise<any> => {
          return (<any>CognitoClient.AWS.config.credentials).data &&
            (<any>CognitoClient.AWS.config.credentials).data.Credentials
            ? Promise.resolve((<any>CognitoClient.AWS.config.credentials).data.Credentials)
            : Promise.reject();
        },
      );

      internalPromise = internalPromise.catch(
        (): Promise<any> => {
          return Promise.resolve();
        },
      );

      return internalPromise;
    });

    return promise;
  }

  /**
   * Returns a promise containing the AWS Cognito credentials created from a given set of developer credentials.
   *
   * @param {{developerId:string, identityId:string; openIdToken:string}} credentials The developer credentials.
   *
   * @returns {Promise<(AWS.Credentials | CredentialsOptions)>} A promise containing the AWS Cognito credentials.
   */
  public static getCognitoIdentityCredentialsForDeveloperIdentity(
    provider: "linkedin" | "twitter",
    credentials: {
      developerId: string;
      identityId: string;
      openIdToken: string;
    },
  ): Promise<any> {
    return import("./aws-sdk").then((module) => {
      return new Promise((resolve: (result) => void, reject: (e: { code: string; message: string }) => void) => {
        CognitoClient.AWS.config.credentials = new module.AWS_CLIENTS.AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
          IdentityId: credentials.identityId,
          Logins: {
            "cognito-identity.amazonaws.com": credentials.openIdToken,
          },
        });
        (CognitoClient.AWS.config.credentials as any).get(
          (
            err: {
              code: string;
              message: string;
            } = null,
          ) => {
            if (err === null) {
              // attempt to save login map in local storage
              localStore.setItem(
                LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
                JSON.stringify({
                  "cognito-identity.amazonaws.com": credentials.openIdToken,
                }),
              );
              switch (provider) {
                case "linkedin":
                  // attempt to save developer id  (linked id - twitter id )
                  // in local storage
                  localStore.setItem(
                    LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS,
                    credentials.developerId,
                  );
                  break;
                case "twitter":
                  // attempt to save developer id  (linked id - twitter id )
                  // in local storage
                  localStore.setItem(
                    LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS,
                    credentials.developerId,
                  );
                  break;

                default:
              }
              // accessKeyId, secretAccessKey, sessionToken, and expireTime
              resolve(CognitoClient.AWS.config.credentials);
            } else {
              // an error occurred
              reject(err);
            }
          },
        );
      });
    });
  }

  /**
   * Returns a promise containing the email of the logged-in user.
   *
   * @returns {Promise<string>} The promise containing the email of the logged-in user.
   */
  static getEmailOfCurrentUser(): Promise<string> {
    let cognitoUser: AmazonCognitoIdentity.CognitoUser;
    const DATA: AmazonCognitoIdentity.ICognitoUserPoolData = {
      ClientId: process.env.AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    };
    let promise: Promise<string>;
    const USER_POOL = new AmazonCognitoIdentity.CognitoUserPool(DATA);

    cognitoUser = USER_POOL.getCurrentUser();
    if (cognitoUser === null) {
      promise = Promise.reject(new Error("[8znnmrwmT8iqzgybQiZYyw] There is no current user"));
    } else {
      promise = new Promise((resolve: () => void, reject: (e: any) => void): void => {
        cognitoUser.getSession((err: any = null, session: AmazonCognitoIdentity.CognitoUserSession = null): void => {
          if (err === null) {
            localStore.setItem(
              LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
              JSON.stringify({
                [process.env.AWS_COGNITO_LOGIN_KEY]: session.getIdToken().getJwtToken(),
              }),
            );
            resolve();
          } else {
            reject(err);
          }
        });
      }).then(
        (): Promise<string> => {
          return new Promise((resolve: (email: string) => void, reject: (e: any) => void): void => {
            cognitoUser.getUserAttributes(
              (err: any = null, attributes: AmazonCognitoIdentity.CognitoUserAttribute[] = null): void => {
                let email: AmazonCognitoIdentity.CognitoUserAttribute;

                if (err === null) {
                  email = attributes.find((attribute: AmazonCognitoIdentity.CognitoUserAttribute): boolean => {
                    return attribute.getName() === "email";
                  });
                  resolve(email.getValue());
                } else {
                  reject(err);
                }
              },
            );
          });
        },
      );
    }
    return promise;
  }

  /**
   * Returns a flag which identifies if the login is anonymous.
   */
  static get isAnonymous(): boolean {
    const loginsMap: string = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP);

    return loginsMap === undefined || loginsMap === null;
  }

  /**
   * Returns a flag which is true if the user has logged in with username and password.
   *
   * @returns {boolean} A flag which is true if the user has logged in with username and password.
   */
  public static isUserHasPassword(): boolean {
    const VALUE: string = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_LOGIN_WITH_EMAIL_PASSWORD_IDENTIFIER);
    return VALUE !== null && VALUE !== undefined;
  }

  /**
   * Returns a linked in id if the user has logged in with linked Auth.
   *
   * @returns {string} A linked in id if the user has logged in with linked Auth.
   */
  public static isLinkedInUser(): boolean {
    return localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS) !== null;
  }

  /**
   * Returns a twitter id if the user has logged in with linked Auth.
   *
   * @returns {string} A twitter id if the user has logged in with linked Auth.
   */
  public static isTwitterUser(): boolean {
    return localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS) !== null;
  }

  /**
   * Returns a promise to login the user with an email address and password.
   *
   * @param {string} email The email address of the user.
   *
   * @param {string} password The password of the user.
   *
   * @returns {Promise<void>} The promise to login the user with a email address and password.
   */
  public static loginWithEmailAndPassword(email: string, password: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: any) => void): void => {
      const loginsMap = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP);

      const authenticationData: AmazonCognitoIdentity.IAuthenticationDetailsData = {
        Username: email.toLowerCase(),
        Password: password,
      };
      const cognitoUserPool: AmazonCognitoIdentity.CognitoUserPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
        ClientId: process.env.AWS_COGNITO_CLIENT_ID,
      });

      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: authenticationData.Username,
        Pool: cognitoUserPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onFailure: (e: any): void => {
          reject(e);
        },
        onSuccess: (session: AmazonCognitoIdentity.CognitoUserSession): void => {
          const accessToken = session.getIdToken().getJwtToken();
          const loginsMap = { [process.env.AWS_COGNITO_LOGIN_KEY]: accessToken };
          localStore.setItem(
            LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
            JSON.stringify(loginsMap),
          );
          // identify this login as login with Email and password.
          localStore.setItem(LocalStorageKeys.KEY_LOCAL_STORAGE_LOGIN_WITH_EMAIL_PASSWORD_IDENTIFIER, "0");

          //CognitoClient.AWS.config.credentials = new CognitoClient.AWS.CognitoIdentityCredentials({
          //  IdentityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
          //  Logins: loginsMap,
          //});

          ////refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          //CognitoClient.AWS.config.credentials.refresh(error => {
          //  if (error) {
          //    console.error(error);
          //    reject(error);
          //  } else {
          //
          //
          //    resolve();
          //  }
          //});

          this.updateCognitoIdentityCredentials()
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        },
      });
    });
  }

  /**
   * Returns a promise to login the user with a Google account.
   *
   * @param {{accessToken:string}} authObject The authentication object.
   *
   * @returns {Promise<void>} The promise to login the user with a Google account.
   */
  public static loginWithFacebookAccount(authObject: { accessToken: string }): Promise<void> {
    return CognitoClient.loginWithIdentityPoolProviderAndToken("graph.facebook.com", authObject.accessToken);
  }

  /**
   * Returns a promise to login the user with a Google account.
   *
   * @param {{tokenId:string}} authObject The authentication object.
   *
   * @returns {Promise<void>} The promise to login the user with a Google account.
   */
  public static loginWithGoogleAccount(authObject: { tokenId: string }): Promise<void> {
    return CognitoClient.loginWithIdentityPoolProviderAndToken("accounts.google.com", authObject.tokenId);
  }

  /**
   * Returns a promise to login the user with a a Cognito identity pool token.
   *
   * @param {CognitoIdentityPoolTokens} tokens The identity pool tokens.
   *
   * @returns {Promise<void>} The promise to login the user with a LinkedIn account.
   */
  public static loginWithIdentityPoolIdTokens(tokens: CognitoIdentityPoolTokens): Promise<void> {
    localStore.setItem(
      LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
      JSON.stringify({
        [process.env.AWS_COGNITO_LOGIN_KEY]: tokens.id_token,
      }),
    );
    this.updateCognitoIdentityCredentials();
    return Promise.resolve();
  }
  /**
   * Returns a promise to login the user with a LinkedIn account.
   *
   * @param {*} authObject The authentication object.
   *
   * @returns {Promise<void>} The promise to login the user with a LinkedIn account.
   */
  public static loginWithLinkedInAccount(authObject: any): Promise<void> {
    return CognitoClient.loginWithIdentityPoolProviderAndToken(
      process.env.AWS_COGNITO_DEVELOPER_IDENTITY_KEY,
      authObject.OpenIdToken,
    );
  }

  /**
   * Returns a promise to login the user with a Twitter account.
   *
   * @param {*} authObject The authentication object.
   *
   * @returns {Promise<void>} The promise to login the user with a Twitter account.
   */
  public static loginWithTwitterAccount(authObject: any): Promise<void> {
    return CognitoClient.loginWithIdentityPoolProviderAndToken(
      process.env.AWS_COGNITO_DEVELOPER_IDENTITY_KEY,
      authObject.accessToken,
    );
  }

  /**
   * Returns a promise to resend the code to confirm the registration of a AWS Cognito account to the user with a given email address.
   *
   * @param {string} email The email address to which to send the AWS Cognito account confirmation code.
   * @returns {Promise<AWS.any>} The promise to resend the code to
   * confirm the registration of an AWS Cognito account to the user with a given email address.
   */
  public static resendAccountConfirmationCode(email: string): Promise<any> {
    return import("./aws-sdk").then((module) => {
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const provider = new module.AWS_CLIENTS.CognitoIdentityServiceProvider();
        const params: any = {
          ClientId: process.env.AWS_COGNITO_CLIENT_ID,
          Username: email.toLowerCase(),
        };
        provider.resendConfirmationCode(params, (err: any = null, result: any = null): void => {
          if (err === null || err === undefined) {
            resolve(result);
          } else {
            reject(err);
          }
        });
      });
    });
  }

  /**
   * Signs the user out and clears the session information.
   *
   * @static
   */
  public static signOut(disableReload?: boolean): Promise<any> {
    const USER = this.getCurrentUser();
    USER?.signOut();

    localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS);
    localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS);
    localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP);
    localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID);
    localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_LOGIN_WITH_EMAIL_PASSWORD_IDENTIFIER);

    CognitoClient.AWS = null;

    if (disableReload !== undefined) {
      const shouldRedirectToHomePage = /account/.test(window.location.href);
      if (shouldRedirectToHomePage) {
        return CognitoClient.updateCognitoIdentityCredentials().then(() => {
          window.location.href = "/";
        });
      } else {
        window.location.reload();
      }
    }

    return CognitoClient.updateCognitoIdentityCredentials();
  }

  /**
   * Returns a promise to verify the creation of the AWS Cognito account with a given email address using a verification code.
   *
   * @param {string} email The email identifying the Cognito account.
   *
   * @param {string} code The code to verify the creation of the account.
   *
   * @returns {Promise<any>} The promise to verify the creation of an AWS
   * Cognito account with a given email address using a verification code.
   */
  public static verifyAccountCreationFromVerificationCode(email: string, code: string): Promise<any> {
    return import("./aws-sdk").then((module) => {
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const params = {
          ClientId: process.env.AWS_COGNITO_CLIENT_ID,
          ConfirmationCode: code,
          Username: email.toLowerCase(),
        };
        const provider = new module.AWS_CLIENTS.CognitoIdentityServiceProvider();

        provider.confirmSignUp(
          params,
          (
            err: {
              code: string;
              message: string;
            } = null,
            result: any = null,
          ) => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      });
    });
  }

  /*    */
  public static verifyUserAttributes(attribute: string, code: string): Promise<any> {
    return Promise.all([this.refreshCognitoLoginsMap(), import("./aws-sdk")]).then((res: any) => {
      const ACCESS_TOKEN: string = res[0];
      const module = res[1];
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const params: VerifyUserAttributeRequest = {
          AccessToken: ACCESS_TOKEN,
          Code: code,
          AttributeName: attribute,
        };

        const provider = new module.AWS_CLIENTS.CognitoIdentityServiceProvider();
        provider.verifyUserAttribute(
          params,
          (
            err: {
              code: string;
              message: string;
            } = null,
            result: any = null,
          ) => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      });
    });
  }

  /*    */
  public static getUserAttributeVerificationCode(attribute: string): Promise<any> {
    return Promise.all([this.refreshCognitoLoginsMap(), import("./aws-sdk")]).then((res: any) => {
      const ACCESS_TOKEN: string = res[0];
      const module = res[1];
      return new Promise((resolve: (result: any) => void, reject: (e: any) => void) => {
        const params: GetUserAttributeVerificationCodeRequest = {
          AccessToken: ACCESS_TOKEN,
          AttributeName: attribute,
        };

        const provider = new module.AWS_CLIENTS.CognitoIdentityServiceProvider();
        provider.getUserAttributeVerificationCode(
          params,
          (
            err: {
              code: string;
              message: string;
            } = null,
            result: any = null,
          ) => {
            if (err === null) {
              resolve(result);
            } else {
              reject(err);
            }
          },
        );
      });
    });
  }

  /**
   *
   *
   * @static
   * @param {*} requestOptions
   * @param {*} keys
   * @memberof CognitoClient
   */
  public static signRequest(requestOptions, keys): void {
    AWS4_SIGN(requestOptions, keys);
  }

  /**
   * Returns the account username generated from an email address. This is a temporary method to address the issue of configuration of
   * the AWS Cognito user pool.
   *
   * @param {string} email The original email address.
   *
   * @returns {string} The username generated from the email address.
   */
  private static getUsernameFromEMail(email: string): string {
    return email
      .replace(/\./g, "dot")
      .replace(/@/, "at")
      .toLowerCase();
  }
  /**
   * Returns a promise to login the user with a provider and identity token.
   *
   * @param {string} provider The provider key.
   *
   * @param {string} token The identity token.
   *
   * @returns {Promise<void>} The promise to login the user with a LinkedIn account.
   */
  private static loginWithIdentityPoolProviderAndToken(provider: string, token: string): Promise<void> {
    localStore.setItem(
      LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
      JSON.stringify({
        [provider]: token,
      }),
    );
    this.updateCognitoIdentityCredentials();
    return Promise.resolve();
  }

  /**
   * Returns a promise to refresh the Cognito Identity credentials. This may involve recreating the AWS Cognito logins map.
   *
   * @returns {Promise<void>} The promise to refresh the Cognito Identity credentials.
   */
  private static refreshCognitoIdentityCredentials(): Promise<any> {
    return new Promise((resolve: () => void, reject: (err: { code: string; message: string }) => void): void => {
      // throw new Error("aaa");
      (CognitoClient.AWS.config.credentials as any).refresh(
        (
          err: {
            code: string;
            message: string;
          } = null,
        ): void => {
          if (err === null) {
            resolve();
          } else {
            reject(err);
          }
        },
      );
    }).catch(
      (e: { code: string; message: string }): Promise<void | {}> => {
        let promise: Promise<any>;
        const USER = this.getCurrentUser();
        USER?.signOut();
        if (e.message.toLowerCase().indexOf("token expired:") === -1) {
          // Unknown error - logout and regenerate.
          promise = Promise.reject();
        } else {
          if (this.isTwitterUser()) {
            // attempt to invoke twitter refresh user L-F
            promise = CognitoClient.refreshTwitterCredentials();
          } else if (this.isLinkedInUser()) {
            promise = CognitoClient.refreshLinkedInCredentials();
          } else {
            // Need to regenerate the logins map since the token has expired
            promise = CognitoClient.refreshCognitoLoginsMap().then(
              (): Promise<any> => {
                CognitoClient.updateCognitoIdentityCredentials();
                return Promise.resolve();
              },
            );
          }
        }
        promise = promise.then(
          (): Promise<any> => {
            return CognitoClient.refreshCognitoIdentityCredentials();
          },
        );
        promise = promise.catch((err) => {
          console.error(err);
          return Promise.reject();
        });
        return promise;
      },
    );
  }

  /*
  get current user  
  */
  private static getCurrentUser(): AmazonCognitoIdentity.CognitoUser {
    const DATA: AmazonCognitoIdentity.ICognitoUserPoolData = {
      ClientId: process.env.AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    };
    let promise: Promise<void>;
    const USER_POOL = new AmazonCognitoIdentity.CognitoUserPool(DATA);

    return USER_POOL.getCurrentUser();
  }
  /**
   * Function that invoke refresh twitter user credential and
   * save new credential in local storage
   *
   * @private
   * @static
   * @param {string} personId
   * @memberof CognitoClient
   */
  private static refreshTwitterCredentials(): Promise<any> {
    let personId: string;
    // fetch user id from local storage
    let userData: any = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER);

    if (userData !== null && userData !== undefined) {
      userData = JSON.parse(userData);
      personId = userData.id;
      return CognitoClient.refreshOpenTokenId(personId).then((refreshResult: any) => {
        return Promise.resolve(refreshResult);
      });
    }
    return Promise.reject();
  }

  /**
   * Function used to refresh linked in users open Id
   * @static
   * @param {string} personId
   * @memberof TwitterAuthenticator
   */
  private static refreshOpenTokenId(personId: string): any {
    const updateTwitterInput: UpdateTwitterUserInput = {
      clientMutationId: undefined,
      id: personId,
    };

    return MutationController.refreshLinkedInToken(updateTwitterInput)
      .then((mutationResults: TwitterUser) => {
        const twitterUserToken: TwitterUser = mutationResults;
        // if query face any error in lambda function

        return CognitoClient.getCognitoIdentityCredentialsForDeveloperIdentity("twitter", {
          developerId: twitterUserToken.twitterId,
          identityId: twitterUserToken.id,
          openIdToken: twitterUserToken.openIdToken,
        });
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(new Error("[Cu9fYK1SSQOn3O2qQPtAOg] could not retrieve openId Token"));
      });
  }

  /**
   * Function that invoke refresh twitter user credential and save new credential in local storage
   *
   * @private
   * @static
   * @param {string} personId
   * @memberof CognitoClient
   */
  private static refreshLinkedInCredentials(): Promise<any> {
    let personId: string;
    // fetch user id from local storage
    let userData: any = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER);

    if (userData !== null && userData !== undefined) {
      userData = JSON.parse(userData);

      personId = userData.id;
      return CognitoClient.refreshOpenTokenIdLinkedIn(personId).then((refreshResult: any) => {
        return Promise.resolve(refreshResult);
      });
    }
    return Promise.reject();
  }

  /**
   * Function used to refresh linked in users open Id
   * @static
   * @param {string} personId
   * @memberof LinkedInAuthenticator
   */
  private static refreshOpenTokenIdLinkedIn(personId: string): Promise<any> {
    const updateLinkedInInput: UpdateLinkedInUserInput = {
      clientMutationId: undefined,
      id: personId,
    };

    // attempt to fetch mutation query to refresh linked-in users openId

    return MutationController.refreshLinkedInToken(updateLinkedInInput)
      .then((res) => {
        const linkedInUserToken: LinkedInUser = res;

        return CognitoClient.getCognitoIdentityCredentialsForDeveloperIdentity("linkedin", {
          developerId: linkedInUserToken.linkedInUserId,
          identityId: linkedInUserToken.id,
          openIdToken: linkedInUserToken.openIdToken,
        });
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(new Error("[Cu9fYK1SSQOn3O2qQPtAOg] could not retrieve openId Token"));
      });
  }

  /**
   * Returns a promise to refresh the AWS Cognito logins map from the current Cognito user. This replaces the (JWT) token in the
   * logins map.
   *
   * @returns {Promise<void>} The promise to refresh the AWS Cognito logins map from the current Cognito user.
   */
  private static refreshCognitoLoginsMap(): Promise<void> {
    let cognitoUser: AmazonCognitoIdentity.CognitoUser;
    const DATA: AmazonCognitoIdentity.ICognitoUserPoolData = {
      ClientId: process.env.AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    };
    let promise: Promise<void>;
    const USER_POOL = new AmazonCognitoIdentity.CognitoUserPool(DATA);

    cognitoUser = USER_POOL.getCurrentUser();
    if (cognitoUser === null) {
      // promise = Promise.reject(new Error("[Wtx8cKpjTo6fiCTwAomCzw] There is no current user"));
      CognitoClient.signOut(true);
      promise = Promise.resolve();
    } else {
      promise = new Promise((resolve: (res: any) => any, reject: (e: any) => void): void => {
        cognitoUser.getSession((err: any = null, session: AmazonCognitoIdentity.CognitoUserSession = null): void => {
          if (err === null) {
            localStore.setItem(
              LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP,
              JSON.stringify({
                [process.env.AWS_COGNITO_LOGIN_KEY]: session.getIdToken().getJwtToken(),
              }),
            );

            resolve(session.getAccessToken().getJwtToken());
          } else {
            reject(err);
          }
        });
      });
    }
    return promise;
  }

  /**
   * Returns a promise to update the AWS (in-memory configuration) credentials with the current Cognito identity pool credentials.
   */
  private static updateCognitoIdentityCredentials() {
    return import("./aws-sdk").then((module) => {
      let credentials: any;
      let identityOptions: any;
      let loginsMap: string;

      identityOptions = {
        IdentityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
        Logins: {},
      };
      // Retrieves the persisted identity pool login's map -
      // if this doesn't exist then login is anonymous
      loginsMap = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_IDENTITY_POOL_LOGINS_MAP);

      if (loginsMap !== undefined && loginsMap !== null) {
        // There is a map - it's no anonymous
        identityOptions.Logins = JSON.parse(loginsMap);
      } else {
        localStore.removeItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER);
      }
      // upon creating new AWS credential
      // expired = true;
      // sessionId, accessId, expiryDate = null
      credentials = new module.AWS_CLIENTS.AWS.CognitoIdentityCredentials(identityOptions);
      CognitoClient.AWS?.config?.credentials = credentials;

      return Promise.resolve();
    });
  }
}
